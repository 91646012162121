<template>
  <LoadingState v-if="isLoading" />
  <div v-else>
    <a-row>
      <a-col :span="24">
        <h1>Report Center</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <div class="card-slots">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item
              v-for="(slot, index) in menuSlots"
              :key="index"
              @click="showOverview(slot.id_mata_pelajaran, slot['kela.tingkat'])"
            >{{slot["kela.tingkat"]}} | {{slot["mata_pelajaran.nama"].toUpperCase()}}</a-menu-item>
          </a-menu>
          <div class="detail-slot mt-4">
            <a-row>
              <a-col :xs="12" :lg="6">
                <h3 class="type">Mata Pelajaran</h3>
                <p class="value">{{overviewReport.mapel ? overviewReport.mapel : '-'}}</p>
              </a-col>
              <a-col :xs="12" :lg="6">
                <h3 class="type">Tingkat</h3>
                <p
                  class="value"
                >{{overviewReport.tingkat ? `Tingkat ${overviewReport.tingkat} (${overviewReport.totalClass} Classes)` : '-'}}</p>
              </a-col>
              <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
                <h3 class="type">Students</h3>
                <p
                  class="value"
                >{{overviewReport.totalStudentByLevel ? overviewReport.totalStudentByLevel : 0}} Students</p>
              </a-col>
              <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
                <h3 class="type">Session Progress</h3>
                <p class="value" v-if="overviewReport.progress">
                  <span>{{overviewReport.progress.currentWeek}}</span>
                  / {{overviewReport.progress.totalWeek}} Session
                </p>
                <p class="value" v-else>-</p>
              </a-col>
            </a-row>
          </div>
          <div class="divider">
            <a-divider orientation="left">
              <h3>Report by Classes</h3>
            </a-divider>
          </div>
          <div class="table-presences">
            <a-table
              class="table-scroll"
              :columns="handleTableColumn"
              :data-source="dataTable"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              :loading="loadingTable"
              bordered
            >
              <a
                slot="Class"
                class="text-primary"
                slot-scope="text, record"
                @click.prevent="toDetailReport(record.idMapel, record.key)"
              >{{ text }}</a>
              <span slot="Score" slot-scope="text">
                <div v-if="text > 70 && text <= 100">
                  <a-tag color="green">{{text}}</a-tag>
                </div>
                <div v-else-if="text > 35 && text <= 70">
                  <a-tag color="orange">{{text}}</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 35">
                  <a-tag color="red">{{text}}</a-tag>
                </div>
              </span>
            </a-table>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import LoadingState from '@/components/app/LoadingState'
const columnGanjil = [
  {
    title: 'Class',
    dataIndex: 'Class',
    key: 'Class',
    // width: '45%',
    scopedSlots: { customRender: 'Class' },
  },
  {
    title: 'Students',
    key: 'Students',
    dataIndex: 'Students',
    // width: '10%',
    scopedSlots: { customRender: 'Students' },
  },
  {
    title: 'Average of Assignments',
    children: [
      {
        title: 'Avg. PH 1',
        dataIndex: 'AvgPH1',
        key: 'AvgPH1',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PH 2',
        dataIndex: 'AvgPH2',
        key: 'AvgPH2',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PTS',
        dataIndex: 'AvgPTS',
        key: 'AvgPTS',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PH 3',
        dataIndex: 'AvgPH3',
        key: 'AvgPH3',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PH 4',
        dataIndex: 'AvgPH4',
        key: 'AvgPH4',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PAS',
        dataIndex: 'AvgPAS',
        key: 'AvgPAS',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
    ],
  },
  {
    title: 'Avg. Report',
    key: 'AvgReport',
    dataIndex: 'AvgReport',
    // width: '10%',
    scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Avg. Report2',
    key: 'AvgReport2',
    dataIndex: 'AvgReport2',
    // width: '10%',
    scopedSlots: { customRender: 'Score' },
  },
]
const columnGenap = [
  {
    title: 'Class',
    dataIndex: 'Class',
    key: 'Class',
    // width: '45%',
    scopedSlots: { customRender: 'Class' },
  },
  {
    title: 'Students',
    key: 'Students',
    dataIndex: 'Students',
    // width: '10%',
    scopedSlots: { customRender: 'Students' },
  },
  {
    title: 'Average of Assignments',
    children: [
      {
        title: 'Avg. PH 1',
        dataIndex: 'AvgPH1',
        key: 'AvgPH1',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PH 2',
        dataIndex: 'AvgPH2',
        key: 'AvgPH2',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PTS',
        dataIndex: 'AvgPTS',
        key: 'AvgPTS',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PH 3',
        dataIndex: 'AvgPH3',
        key: 'AvgPH3',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PH 4',
        dataIndex: 'AvgPH4',
        key: 'AvgPH4',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Avg. PAT',
        dataIndex: 'AvgPAT',
        key: 'AvgPAT',
        // width: 80,
        scopedSlots: { customRender: 'Score' },
      },
    ],
  },
  {
    title: 'Avg. Report',
    key: 'AvgReport',
    dataIndex: 'AvgReport',
    // width: '10%',
    scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Avg. Report2',
    key: 'AvgReport2',
    dataIndex: 'AvgReport2',
    // width: '10%',
    scopedSlots: { customRender: 'Score' },
  },
]

export default {
  data() {
    return {
      current: [0],
      columnGanjil,
      columnGenap,
      dataTable: [],
      loadingTable: false,
      overviewReport: {},
    }
  },
  components: {
    LoadingState,
  },
  methods: {
    toDetailReport(idMapel, idKelas) {
      this.$router.push({ name: 'Detail Report Teacher', params: { idMapel, idKelas } })
    },
    showOverview(idMapel, tingkat) {
      this.fetchDataOverview({
        idMapel,
        tingkat,
      })
    },
    fetchDataOverview(params = { idMapel: '', tingkat: '' }) {
      this.loadingTable = true
      const idGuru = this.user.id
      this.$store.dispatch('report/FETCH_OVERVIEW_ASSIGNMENT_TEACHER', { idGuru, idMapel: params.idMapel, tingkat: params.tingkat })
        .then(res => {
          this.loadingTable = false
          this.overviewReport = res
          // console.log('res', res)
          this.dataTable = res.reportByClasses.map(row => {
            return {
              key: row.id,
              idMapel: params.idMapel,
              Class: row.kelas,
              Students: row.totalMurid,
              AvgPH1: row.avgPH1 ? parseFloat(row.avgPH1).toFixed(2) : 0,
              AvgPH2: row.avgPH2 ? parseFloat(row.avgPH2).toFixed(2) : 0,
              AvgPTS: row.avgPTS ? parseFloat(row.avgPTS).toFixed(2) : 0,
              AvgPH3: row.avgPH3 ? parseFloat(row.avgPH3).toFixed(2) : 0,
              AvgPH4: row.avgPH4 ? parseFloat(row.avgPH4).toFixed(2) : 0,
              AvgPAS: row.avgPAS ? parseFloat(row.avgPAS).toFixed(2) : 0,
              AvgPAT: row.avgPAT ? parseFloat(row.avgPAT).toFixed(2) : 0,
              AvgReport: row.avgReport ? parseFloat(row.avgReport).toFixed(2) : 0,
              AvgReport2: row.avgReport2 ? parseFloat(row.avgReport2).toFixed(2) : 0,
            }
          })
        })
    },
  },
  created() {
    this.$store.dispatch('report/FETCH_MENU_SLOT_TEACHER', { idGuru: this.user.id })
      .then(data => {
        this.loadingTable = true
        this.$store.commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        if (data[0]) {
          this.fetchDataOverview({
            idMapel: data[0].id_mata_pelajaran,
            tingkat: data[0]['kela.tingkat'],
          })
        } else {
          this.$store.commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
        }
      })
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    },
    user() {
      return this.$store.state.user.user
    },
    menuSlots() {
      return this.$store.state.report.menuSlots
    },
    handleTableColumn() {
      let columns
      // console.log(this.selectedMapel)
      if (this.overviewReport.semester === 'Ganjil') {
        columns = this.columnGanjil
      } else {
        columns = this.columnGenap
      }
      return columns
    },
  },
}
</script>

<style lang="scss">
.card-slots {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail-slot {
  padding: 40px 20px;
}

.detail-slot .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail-slot .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}

.detail-slot .value span {
  color: #1b90ff;
}

.progress-card {
  padding: 10px 20px;
}

.table-presences {
  padding: 10px 20px 20px 20px;
}

.table-scroll {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
  }
}
</style>
